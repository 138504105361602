.skeleton {
  position: relative;
  // background-color: #f3f3f3;
  background-color: var(--kt-gray-300);
  overflow: hidden;
  border-radius: 0.475rem;
  // width: 300px;
}
.skeleton::before,
.skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    var(--kt-gray-300) 0%,
    var(--kt-gray-200) 50%,
    var(--kt-gray-300) 75%
  );
  animation: skeleton-loading 2.5s infinite linear;
}
@keyframes skeleton-loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.control-offcanvas{
    @media only screen and (max-width: 600px) {
        width: 100% !important;
    }

    @media only screen and (min-width: 600px) {
        width: 95% !important;
    }

    @media only screen and (min-width: 768px) {
        width: 90% !important;
    }

    @media only screen and (min-width: 1200px) {
        width: 85% !important;
    }

    @media only screen and (min-width: 1900px) {
        width: 80% !important;
    }
}
.rmsc {
    --rmsc-h: 46px !important;
    --rmsc-main: none !important;
    --rmsc-border: none !important;
    --rmsc-radius: 0.625rem !important;
    --rmsc-bg: var(--kt-input-solid-bg) !important;
    --rmsc-hover: var(--kt-input-solid-bg-focus) !important;
    --rmsc-selected: var(--kt-primary-light) !important;


    box-shadow: none !important;
    font-size: 1.15rem !important;
    border-radius: 0.625rem !important;
	transition: $transition-input !important;
    color: var(--kt-text-gray-900) !important;
    @include placeholder(var(--kt-input-placeholder-color));

    .search {
        input {
            color: var(--kt-text-gray-900) !important;
          }
    }
    
    .item-renderer {
        input {
            display: none;
          }

    } 

    .dropdown-content {
        .panel-content {
            background:  var(--kt-input-solid-bg) !important;
            box-shadow: none !important;
          }
    } 


    .gray {
        color:var(--kt-input-placeholder-color) !important;
        font-weight: 500;
    }

    .dropdown-heading {
        padding: 0 21px !important;
    }
  }
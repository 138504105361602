//
// WaveSurfer
//

.waveform ::part(cursor) {
    top: 29px;
    background-color: red;
}

.waveform ::part(cursor):after {
    content: '🔊';
    font-size: 1.6em;
    position: absolute;
    left: 3px;
    top: -29px;
    transform: translateX(-50%);
}

.waveform ::part(region)::after {
    content: '🖇️';
    font-size: 1.8em;
    position: absolute;
    top: 7px;
    transform: translateX(-45%);
}

.waveform ::part(marker) {
    border: 3px solid rgba(197, 167, 244, 0.01);
}

.waveform ::part(marker):after {
    content: '📎';
    font-size: 1.7em;
    position: absolute;
    top: 4px;
    transform: translateX(-50%);
}
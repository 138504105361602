.rdtPicker td.rdtActive {
    background-color: $primary!important
}

.rdtPicker td.rdtToday::before {
    border-bottom: 7px solid $primary!important
}

.rdtPicker .rdtDay {
    color: $dark!important    
}

.rdtPicker td.rdtOld, .rdtPicker td.rdtNew {
    color: #999999 !important
}


  .rdtPicker .rdtTimeToggle, .rdtSwitch, .rdtPrev, .rdtNext, .rdtTime {
    color: $dark!important    
}
.daterangepicker {
    .ranges {
        ul {
          overflow: hidden !important;
          min-height: 310px;
        }
        li {
            &.active{

                background-color: var(--kt-color-primary);
                color: #fff;
            }
          }
    }
    
}

.daterangepicker {
    .ranges {
        ul {
            max-height: 350px;
          }
    } 
} 